@import "pyrat_basic";

.location-picker,
.tank-picker,
.cage-picker {
    border: 1px solid $shade-color-74;
    border-radius: 2px;
    box-sizing: border-box;
    background: $contrast-color;
    display: inline-block;
    width: 450px;
    overflow-y: auto;
    height: 300px;
    position: relative;
    font-size: $medium-bigger-font-size;
    line-height: $medium-bigger-font-size + 2px;
    color: $second_color_900;

    .caption {
        margin: 5px;
    }

    &.disabled {
        border-color: transparent;
        background-color: $shade-color-90;

        .selectable:hover {
            background-color: transparent;
            border-color: transparent;
        }
    }
}

.cage-picker {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.location-picker-popup .controls,
.location-cage-picker-popup .controls {
    text-align: center;
    padding: 5px 0;
}

.location-picker-popup .location-picker {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
}

.location-cage-picker-popup .location-picker {
    border-left-width: 0;
    border-top-width: 0;
}

.location-cage-picker-popup .tank-picker,
.location-cage-picker-popup .cage-picker {
    border-right-width: 0;
    border-top-width: 0;
}

.location-cage-picker {
    height: 200px;
    .location-picker {
        float: left;
        width: 43%;
        height: 100%;
    }

    .cage-picker, .tank-picker {
        float: right;
        width: 56%;
        height: 100%;
    }
}

.location-picker {

    table {
        border-collapse: collapse;
        width: 100%;

        .building .location-cell .location,
        .area .location-cell .location,
        .room .location-cell .location,
        .rack .location-cell .location {
            flex-grow: 1;
        }
    }

    .location-cell {
        position: relative;
        padding: 1px;
        display: flex;
    }

    .expander {
        position: absolute;
        display: none;
    }

    .expandable .expander {
        transition: 0.2s ease-in-out;
        width: 15px;
        height: 15px;
        padding: 0px !important;
        display: inline-block;
        text-align: center;
    }

    .expanded .expander {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .location {
        display: inline-block;
        margin: 0 0 0 19px;
        word-break: break-all;
        select { padding-right: 20px }
    }

    tr.area .location-cell {
        padding-left: 15px;
    }

    tr.room .location-cell {
        padding-left: 30px;
    }

    tr.rack .location-cell {
        padding-left: 40px;
    }

    ko-location-picker.invalid_data & {
        @include invalid-data;
    }
}

.cage-picker {
    width: 320px;

    .cage {
        float: left;
        margin: 1px 3px;
        text-align: center;

        .suffix,
        .prefix {
            float: left;
            padding-right: 1px;
        }

        .cage_separator {
            float: left;
            padding: 0 3px;
        }
    }
}

.tank-picker {
    width: 320px;

    .tank {
        float: left;
        margin: 1px 5px 1px 3px;
        text-align: center;

        &.in_use span {
            opacity: 0.5;
        }
    }
}
