@import "pyrat_basic";

div.procedure_widget {

    .add_procedure_section {

        .add_procedure_link {
            cursor: pointer;
            padding: 5px;

            &:hover ._add_interactive {
                color: #00b300;
            }

            span.add_procedure_label {
                line-height: 22px;
                vertical-align: top;
            }
        }

        table {
            width: 95%;
            empty-cells: show;
            border-spacing: 0;
            margin: 5px;

            td.label {
                vertical-align: top;
                padding-top: 3px;
            }
        }

        table.for_qs {
            width: 100%;
            margin: -2px 0 0 0;

            td.label {
                min-width: 160px;
                max-width: 160px;
            }
        }
    }

    table {
        > thead > tr > th {
            padding: 2px 10px;
            font-size: 12px;
        }

        > tbody > tr {
            &.delete {
                text-decoration: line-through;
            }

            > td {
                padding-top: 5px;
                padding-bottom: 3px;
                vertical-align: baseline;

                > span.txt-left,
                > span.txt-right {
                    display: block;
                }

                > span.eventfooter {
                    font-size: $small-font-size;
                    font-style: italic;
                }
            }
        }

        .w40 {
            min-width: 40px;
            max-width: 40px;
        }
    }
}
