@import "pyrat_basic";

.action_log {
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: auto;
    box-sizing: border-box;
    font-size: $medium-bigger-font-size;

    .action_log_title {
        margin-bottom: 3px;
        padding: 3px;

        b {
            color: $shade-color-30;
        }
    }

    .action_log_print,
    .action_log_export {
        float: right;
    }

    .action_log_print {
        margin-left: 10px;
    }

    .action_log_events {
        padding: 3px;
        overflow: auto;

        .action_log_summary {
            margin: 1px 0 0 10px;

            .new {
                margin: 2px;
                font-size: $medium-font-size;
                color: $second_color_900;

                .icon_button {
                    font-size: 14px;
                    line-height: 14px;
                }
            }

            .old-new {
                padding: 0 2px;
                border: 1px solid transparent;

                &:hover {
                    border-radius: 2px;
                    border-color: $second_color_900;
                    color: $primary-color;
                }
            }
        }
    }

    .action_log_header,
    .action_log_summary,
    .action_log_content,
    .action_log_footer {
        margin: 0;
        padding: 2px;
        line-height: 120%;
    }

    .action_log_header,
    .action_log_content {
        line-height: 110%;
        font-size: $base-font-size;
    }

    .action_log_footer {
        border-bottom: 1px solid $shade-color-59;
        font-size: $small-font-size;
        line-height: 65%;
        padding: 4px 0 6px;
        text-align: right;
        margin-bottom: 5px;
    }

    .action_log_content {

        .cages,
        .animals,
        .pups,
        .tanks,
        .stud-males,
        .order-requests,
        .service-requests {
            font-style: italic;
            font-weight: bold;
            color: $second_color_900;

            &:hover {
                color: $shade-color-30;
            }
        }

        .cagenumber,
        .animal-eartag,
        .pup-eartag,
        .order-request-number,
        .service-request-number {
            color: $second_color_900;

            &:hover {
                color: $primary-color;
            }
        }

        .square_bracket_left {
            font-size: $medium-bigger-font-size;
            padding-left: 5px;
        }

        .square_bracket_right {
            font-size: $medium-bigger-font-size;
            padding-right: 5px;
        }

    }

}
