@import "pyrat_basic";


ce-sparkline {
    position: relative;
    display: inline-block;
    width: 140px;
    height: $base-font-size * 2;
    background-color: $contrast-color;
    padding: 1px;
    border: 1px solid $shade-color-90;
    border-radius: 2px;
}

#ce-sparkline-tooltip {
    background-color: transparentize($primary-color, .2);
    color: $contrast-color;
    border-radius: 5px;
}

.doughnut-graph {
    position: relative;
    display: inline-block;
    height: 150px;
    width: 150px;
    padding: 1px;
}
