@import 'pyrat_basic';

.ui-dialog {
  ko-slider {
    .noUi-connect {
      background: $second_color_500;
    }
  }
}

ko-slider {

  .noUi-connect {
    background: $basic_color_500;

  }

  &.hide-values .noUi-value {
    display: none;
  }

  &.noUi-horizontal {
    .noUi-handle {
      cursor: ew-resize;
    }
  }

  &.noUi-vertical {
    .noUi-handle {
      cursor: ns-resize;
    }
  }

  // helper: https://codepen.io/garetmckinley/pen/oLBGWR
  &.small-slider {

    $control-width: 20px;
    $control-height: 10px;
    $gutter-height: 5px;

    .noUi-handle {
      height: $control-height;
      margin-top: $gutter-height*0.5;

      &::before, &::after {
        height: $control-height * 0.5;
        top: 25%;
      }
    }

    &.noUi-horizontal {
      height: $gutter-height;
    }

    &.noUi-vertical {
      width: $gutter-height;
      margin-left: -($control-width*0.5) + $gutter-height*0.5;
    }
  }
}
