@import "pyrat_basic";

// Progress bar
//
// Activated when a document is being upladed.
//
// Markup:
//   <ko-progress>
//       <div style="height: 1em; width: 200px">
//           <span class="bar" style="width: 21%;"></span>
//           <span class="label">21%</span>
//       </div>
//   </ko-progress>
//
// Style guide: elements.progress-bar
ko-progress {
    div {
        background-color: $shade-color-82;
        width: 100%;
        text-align: left;
        position: relative;
        .bar {
            background-color: $second_color_500;
            display: inline-block;
            height: 100%;
            width: 0;
        }
        .label {
            color: #000;
            font-size: 0.9em;
            text-align: center;
            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            &.gt50 {
                color: $contrast-color;
            }
        }
    }
}
