@import "pyrat_basic";

#order_request_detail {

  .order_form_container {
      padding: 4px 0;
      margin-bottom: 4px;

      .line_content {
          clear: both;
          padding: 1px 2px 2px 0;
          height: 22px;
          line-height: 24px;
      }

      .flexible_container {

         input[type="checkbox"] {
             margin-right: 5px;
         }
      }
  }

  div.boxed {
      background-color: $contrast-color;
      padding: 5px;
  }

  .add_catalog_item_section {
      padding: 5px 0;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 3px;

      .add_catalog_item_link {
          cursor: pointer;
          line-height: 22px;
          padding: 5px;

          .icon_button {
              line-height: 22px;
          }

          &:hover ._add_interactive {
              color: #00b300;
          }

          span.add_catalog_item_label {
              vertical-align: top;
          }
      }
  }
}
