@import "pyrat_basic";

// Noty bubble
//
// .noty_type__success  - Success.
// .noty_type__error  - Error.
// .noty_type__information  - Information.
// .noty_type__confirm  - Confirmation.
//
// Markup:
//   <div class=".noty_theme__pyrat noty_bar {{modifier_class}}" style="width: 310px;">
//       <div class="noty_body">
//           <span class="noty_text">{{or text 'Object updated.'}}</span>
//       </div>
//   </div>
//
// Style guide: elements.noty
.noty_theme__pyrat {

    &.noty_bar {
        border: 1px solid $shade-color-74;
        box-shadow: 0 3px 4px $shade-color-74;
        margin: 5px 3px;
        color: $shade-color-30;
        background-color: $shade-color-97;
        font-size: $large-font-size;

        &.noty_effects_open {
            @keyframes noty_pyrat_anim_in {
                0% {
                    transform: translate(-50%);
                    opacity: 0;
                }
                100% {
                    transform: translate(0);
                    opacity: 1;
                }
            }
            animation: noty_pyrat_anim_in .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        &.noty_effects_close {
            @keyframes noty_pyrat_anim_out {
                0% {
                    transform: translate(0);
                    opacity: 1;
                }
                100% {
                    transform: translate(50%);
                    opacity: 0;
                }
            }
            animation: noty_pyrat_anim_out .3s cubic-bezier(0.68, 0, 0.265, 1.55);
        }

        .noty_body {
            padding: 24px 24px 24px 60px;
        }

        .noty_buttons {
            padding: 0 10px 10px 60px;
            text-align: right;
            button {
                margin-left: 10px;
                min-width: 20%;
            }
        }
    }

    &.noty_type__warning,
    &.noty_type__error {
        border-color: $error-color;

        .noty_body {
            &::before {
                position: absolute;
                left: 16px;
                top: 24px;
                font-family: $icons-font-family;
                font-size: 34px;
                color: $error-color;
                content: 'X';
            }
        }
    }

    &.noty_type__info,
    &.noty_type__alert,
    &.noty_type__notification,
    &.noty_type__information {
        border-color: $second_color_700;

        .noty_body {
            &::before {
                position: absolute;
                left: 16px;
                top: 24px;
                font-family: $icons-font-family;
                font-size: 34px;
                color: $second_color_700;
                content: 'ℹ';
            }
        }
    }

    &.noty_type__success {
        border-color: $success-color;

        .noty_body {
            &::before {
                position: absolute;
                left: 16px;
                top: 24px;
                font-family: $icons-font-family;
                font-size: 34px;
                color: $success-color;
                content: '✔';
            }
        }
    }

    &.noty_type__confirm {
        border-color: $shade-color-59;

        .noty_body {
            &::before {
                position: absolute;
                left: 16px;
                top: 24px;
                font-family: $icons-font-family;
                font-size: 34px;
                color: $shade-color-59;
                content: '？';
            }
        }
    }
}
.noty_layout#noty_layout__center {
    width: 390px;
    will-change: transform;
}
