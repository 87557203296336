@import "pyrat_basic";

@mixin list-comment-reply {
    padding-left: 25px;

    &::before {
        content: '⏎';
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        font-family: $icons-font-family;
        opacity: .6;
    }
}

@mixin list-comment-reply-pending {
    padding-left: 25px;

    &::before {
        content: '？';
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        font-family: $icons-font-family;
        color: $warning-color;
        opacity: .6;
    }
}

@mixin comment-attribute {
    border: 1px solid $shade-color-82;
    border-radius: 3px;
    white-space: nowrap;
    background-color: $shade-color-90;
    color: $primary-color;
    margin-right: 2px;
    margin-left: 0;
    margin-bottom: 4px;
    display: inline-block;
    padding: 2px;

    span {
        margin-right: 2px;

        &.icon_button {
            vertical-align: sub;
        }

        &:first-child {
            margin-left: 2px;
        }

        &.attribute_value {
            font-style: italic;
        }
    }
}
