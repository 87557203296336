@import "pyrat_basic";

ko-documents {
    $small-button-width: 20px;

    .dropzone {
        box-sizing: border-box;
        border: 2px dashed $shade-color-74;
        // flex fallback for IE10
        display: block;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        background-color: $contrast-color;

        input[type=file] {
            display: none;
        }

        * {
            pointer-events: none;
        }

        &.drag-over {
            border-color: $contrast-color;
            background-color: $shade-color-90;
        }

        transition-property: background-color, border-color;
        transition-duration: 200ms;
    }

    table {
        margin-top: 5px;
        border-collapse: collapse;

        th {
            height: auto !important;
        }

        td {
            padding: 2px;
        }

        td.name {

            text-align: left;

            .subtle-link {
                display: inherit;
                word-break: break-all;
            }

            > span {
                padding-left: 3px;
                word-break: break-all;
                word-wrap: anywhere;
            }
        }

        td.size {
            width: 1px;

            &.integer {
                padding-right: 0 !important;
                text-align: right;
            }

            &.decimal-symbol {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            &.fraction {
                padding-left: 0 !important;
            }

            &.unit {
                padding-right: 5px;
                color: $second_color_700;
                text-align: left;
            }
        }
    }
}

[data-attached-to=animal],
[data-attached-to=user],
[data-attached-to=strain],
[data-attached-to=order] {
    cursor: pointer;
}
