@import "pyrat_basic";
@import "comment";

ko-comment-widget.invalid_data textarea {
    @include invalid-data;
}

.comment_widget {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    $reply-inset: 25px;

    .comment_container {
        flex-grow: 1;
        overflow: auto;
    }

    @mixin attribute-list() {

        span.attribute {
            @include comment-attribute;

            &:first-child {
                margin-top: 5px;
            }

            &:last-child {
                // margin-bottom: 5px;
            }
        }
    }

    div.comment_bubble {
        border: solid $shade-color-90 1px;
        background-color: $shade-color-97;
        margin: 0px 5px 5px 5px;
        padding: 5px;
        border-radius: 5px;

        &.is_reply {
            margin-left: 20px;
            background-color: lighten($second_color_100, 5);
        }

        &.blurred_out {
            opacity: .2;
            filter: grayscale(100%);
        }

        .line_comment {
            margin-bottom: 3px;
            margin-left: 5px;

            &:first-of-type {
                margin-bottom: 5px;
                margin-left: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.attribute_list {
                @include attribute-list;
            }
        }

        .comment_meta {
            margin-top: 3px;
            opacity: .9;
            font-size: 90%;
            .comment_author {
                font-weight: bold;
            }
        }
    }

    div.comment_creator {

        flex-grow: 0;
        margin-top: 10px;
        padding: 5px;

        textarea {
            width: 100%;
            padding-bottom: 3px;
            padding-top: 3px;
        }

        div.attribute_list {
            @include attribute-list;
            padding: 2px 0;
        }

        div.options {
            input[type=checkbox] {
                margin: 0 5px 5px 0;
            }
        }

        div.buttons {

            span.attributes {
                display: flex;
                margin-top: 5px;

                select {
                    // flex-grow: 1;
                }

                > * {
                    margin-left: 3px;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                input[type="text"] {
                    width: 40%;
                }
            }

            span.actions {
                float: right;

                input.send {
                    margin: 5px;
                }
            }
        }
    }

    // Hiding the comment list and adding some edition to comment creator when the coment list is in blind mode.
    &.blind_mode {
        background-color: transparent;

        div.comment_creator {
            padding: 0;
            margin: 0;

            div.attribute_list {
                padding: 0;
                max-height: 120px;
                overflow: auto;

                span.attribute {
                    padding: 1px;

                    span.attribute_label {
                        font-size: $base-font-size;
                    }

                    span.icon_delete {
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
