@import "pyrat_basic";

/* tab styles */
nav.popup_tabs {
    display: flex;

    a {
        display: flex;
        background-color: darken($shade-color-90, 5);
        color: $shade-color-21;
        border-right: 2px solid $shade-color-93;
        border-left: 2px solid $shade-color-93;
        text-align: center;
        font-size: $medium-font-size;
        padding: 0.25rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;

        &.select {
            background-color: $contrast-color;
            font-weight: bold;
            color: $second_color_900;
        }

        &:not(.select):hover {
            background-color: $shade-color-90;
        }
    }
}


/* page styles */
#midbox, .midbox {
    /* TODO: don't set this here, set this in the pages own styles */
    width: 100%;
    background-color: $contrast-color;
    .popup_frame,
    .popup_footer {
        background-color: transparent;
    }
}
.tab_section {
    overflow: auto;
    background-color: $contrast-color;

    .tab-page {
        box-sizing: border-box;
        background-color: $contrast-color;
        color: $shade-color-21;
        padding: 7px 10px;

        .tab_page_inner_box {
            padding-bottom: 8px;
        }

        .tabdiv {
            position: relative;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .tab_footer {
            text-align: right;
            margin: 5px 0;

            input,
            button,
            .button {
                margin: 0 10px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

.row {
    margin: 5px;
}
