@import 'pyrat_basic';

.tank_detail {

    span.topic {
        padding-bottom: 5px;

        h1 {
            font-size: $large-font-size;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 10px;
        }
    }

    > .tab_section {

        > div.summary {

            color: $second_color_900;
            display: grid;
            grid-template-columns: repeat(8, 1fr);

            > hr {
                grid-column: 1 / -1;
                border: none;
                position: absolute;
            }

            > fieldset {

                display: inherit;
                border: none;
                background-color: $shade-color-97;
                min-height: 3 * $base-font-size;
                grid-column: span 2;
                justify-content: flex-start;
                align-items: center;
                padding: inherit;
                margin: 0 2px;

                > legend {

                    width: fit-content;
                    height: fit-content;
                    user-select: none;
                    font-size: $small-font-size;
                    background-color: $contrast-color;
                    padding: 1px 6px;
                    margin-left: -6px;
                    margin-bottom: -4px;
                    border-radius: 4px;
                    color: $second_color_600;
                }

                &.half-columns {
                    grid-column: span 1;
                }

                &.two-columns {
                    grid-column: span 4;
                }

                &.three-columns {
                    grid-column: span 6;
                }

                &.four-columns {
                    grid-column: span 8;
                }

                &.two-rows {
                    grid-row: span 2;
                }
            }
        }

        > div.history {

            > table {

                tr.event {
                    &.revised .type_label {
                        color: $shade-color-30;
                        font-style: italic;
                    }
                }

                tr.tank_ids {
                    td {
                        color: $shade-color-30;
                        font-size: $base-font-size;
                        border-bottom: none;
                    }
                }

                tr.comment td {
                    border-bottom: none;

                    p {
                        border-radius: 3px;
                        opacity: .7;
                        margin: 2px;
                        padding: 4px 10px;
                        background-color: $second_color_100;
                        white-space: pre-wrap;
                    }
                }


                td, th {
                    &.count {
                        text-align: right;
                        padding-right: 0;
                    }

                    &.icon_cell .icon_button {
                        font-size: 18px;
                   }
                }
            }

        }



        > div.crossings {
            padding: 7px 5px 5px;

            div.actions {
                padding: 10px;
                text-align: right;
            }

            div.no_crossing_records {
                padding: 20px;
                text-align: center;
            }

            table.crossing_records {
                tr {
                    td,
                    th {
                        padding: 2px 10px;
                        text-align: left;

                        &.spacer {
                            width: 5px;
                            padding: 0;
                        }

                        &.responsible_fullname {
                            width: 120px;
                        }

                        &.icon_cell {
                            white-space: nowrap;
                            padding-right: 5px;

                            .icon_button {
                                font-size: 18px;
                           }
                        }

                        &.icon_crossing_children,
                        &.icon_crossing_parent,
                        span.icon_crossing_children,
                        span.icon_crossing_parent {
                            white-space: nowrap;
                            line-height: $extra-large-font-size;
                            font-size: 18px;
                        }

                        &.center_cell {
                            text-align: center;
                        }

                        &.parents_number_of_unknown,
                        &.parents_number_of_male,
                        &.parents_number_of_female,
                        &.children_number_of_unknown,
                        &.children_number_of_male,
                        &.children_number_of_female {
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &.performance .overwritten {
                            text-decoration: underline;
                        }
                    }

                    &.crossing {

                        &.is_discarded td:not(.icon_cell) {
                            text-decoration: line-through;
                        }

                        td {
                            padding-top: 5px;
                            padding-bottom: 5px;
                            background-color: $shade-color-97;
                        }
                    }

                    &.tank {
                        td {
                            color: $shade-color-30;
                            font-size: $base-font-size;
                            border-bottom: none;

                            span.tank_location {
                                padding-left: 10px;
                                opacity: .7;
                            }
                        }

                        &.self td span.tank_id {
                            text-decoration: underline;
                        }
                    }

                    &.description td {
                        border-bottom: none;

                        p {
                            border-radius: 3px;
                            opacity: .7;
                            margin: 2px;
                            padding: 4px 10px;
                            background-color: $second_color_100;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }
}

.update_tank_crossing_popup {
    padding: 10px;
    color: $second_color_900;

    h3:first-child {
        margin-top: 0;
    }

    label {
        line-height: $base-font-size * 1.8;
        margin-top: 2px;
    }

    textarea {
        resize: none;
        min-height: 50px;
    }
}
