@import 'pyrat_basic';
@import 'pyrat_colors';

@mixin action-classes() {

    &.selected {
        border: 4px solid $light-red;
    }

    &.highlighted {
        $highlight-color: transparentize($yellow-color, .7);
        // pastel yellow zig-zag pattern
        box-shadow: 0 0 0 2px $highlight-color;
        background: linear-gradient(135deg, transparentize($highlight-color, .2) 25%, transparent 25%) -10px 0,
                    linear-gradient(225deg, transparentize($highlight-color, .2) 25%, transparent 25%) -10px 0,
                    linear-gradient(315deg, transparentize($highlight-color, .2) 25%, transparent 25%),
                    linear-gradient(45deg, transparentize($highlight-color, .2) 25%, transparent 25%);
        background-size: 20px 20px;
        background-color: $contrast-color;
    }

    &.option {
        border: 2px dashed $basic_color_700;
    }
}

#building_map {

    .board_edge {
        overflow: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 25px 10px;
        display: grid;
        grid-template-columns: min-content min-content;
        grid-gap: 20px;
    }

    .controls {

        border: 1px solid darken($shade-color-90, 6);
        background-color: $shade-color-97;
        color: $primary-color;
        padding: 5px;
        height: max-content;

        input,
        select,
        div {
            vertical-align: middle;
        }

        > hr {
            margin: 10px 0;
            border-color: $shade-color-90;
        }

        > .cage_label {
            font-size: $medium-bigger-font-size;
            margin-bottom: 5px;
            font-weight: bold;
        }

        > .box {
            margin: 2px 5px;
            width: 320px;

            > label {
                display: inline-block;
                width: 30%;
                float: left;
                line-height: $base-font-size * 2;

                + div.right_column,
                + select,
                + input {
                    display: inline-block;
                    width: 70%;
                }

                + .action_in_progress {
                    height: $base-font-size * 2;
                }
            }

        }

        .list_of_details {
            margin: 0;
            padding-left: 20px;

            .detail_to_show {
                border-radius: 3px;
                border: 1px solid $shade-color-74;
                display: inline-block;
                margin: 0 0 3px 5px;
                padding: 3px;

                .icon_button {
                    line-height: 0;
                    vertical-align: sub;
                }
            }
        }

        @media print {
            display: none;
        }
    }

    .rack_selector {

        .location-picker {
            width: 100%;
            height: 240px;
        }

        .sticky_location,
        .selected_location {
            border: 1px solid $second_color_300;
            border-radius: 5px;
            padding: 2px 2px 2px 4px;
            margin-bottom: 4px;
            white-space: nowrap;
            display: inline-block;

            .location_name {
                position: relative;
                top: -2px;
            }

            .icon_button {
                font-size: 22px;
                line-height: 17px;
            }

        }

        .selected_location {
            background-color: white;
        }

        .sticky_location {
            background-color: $second_color_100;
        }

        .visible_racks {
            border: 1px solid $shade-color-74;
            border-radius: 2px;
            background: $contrast-color;
            margin: 5px 0 0 0;
            padding: 4px 4px 0 4px;
        }
    }

    .rack_overview {

        transform-origin: top left;
        display: grid;
        grid-gap: 20px;
        padding-right: 40px;
        padding-bottom: 40px;

        > .rack {

            grid-row: 1;

            > .rack_name {
                margin-right: 20px;
            }

            > .rack_layout {

                display: grid;
                grid-auto-columns: 70px;
                grid-auto-rows: 55px;
                grid-gap: 5px;

                > .cell:not(.blind_cell) {

                    $position-size: 10px;
                    $position-color: $shade-color-50;

                    @include action-classes;
                    display: grid;
                    position: relative;
                    grid-template-rows: 1fr $position-size;

                    .cell_content {
                        // having a transparent background is important to keep the highlighting visible
                        background-color: rgba(0, 0, 0, .03);
                        overflow: auto;

                        &.half_width * {
                            font-size: $base-font-size * 0.5;
                        }

                        .foreign_entry {
                            border: 2px solid $shade-color-82;
                            // this is for occupied position without the right to see its content
                            height: 80%;
                            margin: 2px;
                            overflow: hidden;

                            &::after {
                                $inner-padding: 5px;
                                content: ' ';
                                position: absolute;
                                top: $inner-padding;
                                right: $inner-padding;
                                left: $inner-padding;
                                height: $base-font-size * 1.5;
                                background-color: $shade-color-90;
                            }
                        }

                        .cage,
                        .tank {
                            @include action-classes;
                            border: 1px solid  $shade-color-90;
                            margin: 2px;
                            padding: 2px;
                            background-color: $contrast-color;

                            .cage_label,
                            .tank_label {
                                font-size: $medium-bigger-font-size;
                                margin-bottom: 5px;
                                font-weight: bold;
                            }

                            .owner_full_name,
                            .cage_label,
                            .tank_label,
                            .responsible_full_name,
                            .projects,
                            .licence_number,
                            .birth_date,
                            .open_date,
                            .number_of_animals,
                            .strain_name,
                            .work_requests {
                                border-bottom: 1px solid $shade-color-93;
                                min-height: 10px;
                                font-size: $small-font-size;
                                padding: 2px 0;
                            }
                        }

                    }

                    > .cell_position {
                        border-top: 1px solid $position-color;
                        font-family: monospace;
                        font-size: $position-size;
                        line-height: $position-size;
                        vertical-align: top;
                        text-align: center;
                        color: $position-color;
                    }

                }

            }

            > .unassociated_cages,
            > .unassociated_tanks {
                display: grid;
                grid-template-columns: repeat(8, max-content);
                grid-gap: 10px;

                .cell {
                    @include action-classes;
                    border: 1px solid $shade-color-90;
                    background-color: $shade-color-97;
                    padding: 10px;
                }
            }
        }

        &.action_in_progress,
        &.rack_overview_loading {
            .unassociated_cages,
            .unassociated_tanks,
            .tank,
            .cage {
                -webkit-animation: loading 1s ease infinite;
                -moz-animation: loading 1s ease infinite;
                -o-animation: loading 1s ease infinite;
                animation: loading 1s ease infinite;

                @-webkit-keyframes loading {
                    0% { opacity: .8; }
                    50% { opacity: .2; }
                    100% { opacity: .8; }
                }
                @-moz-keyframes loading {
                    0% { opacity: .8; }
                    50% { opacity: .2; }
                    100% { opacity: .8; }
                }
                @-o-keyframes loading {
                    0% { opacity: .8; }
                    50% { opacity: .2; }
                    100% { opacity: .8; }
                }
                @keyframes loading {
                    0% { opacity: .8; }
                    50% { opacity: .2; }
                    100% { opacity: .8; }
                }
            }
        }
    }

}
