@import "pyrat_basic";

ko-color-picker {

    $vertical-offset: -10px;
    $horizontal-offset: 10px;

    .colorpicker {
        position: relative;
    }

    .colorpicker-pane {
        z-index: 1;
        padding: 10px;
        position: absolute;
        background-color: white;
        border: 1px solid $shade-color-82;
        border-radius: 5px;
        min-width: 300px;
        transform: translate($horizontal-offset, $vertical-offset);
    }

    &.to_the_left .colorpicker-pane {
        transform: translate(calc(-100% + #{$horizontal-offset}), $vertical-offset);
    }

    &.to_the_top .colorpicker-pane {
        transform: translate($horizontal-offset, calc(-100% + #{$vertical-offset}));
    }

    &.to_the_top.to_the_left .colorpicker-pane {
        transform: translate(calc(-100% + #{$horizontal-offset}), calc(-100% + #{$vertical-offset}));
    }

    .colorpicker-grid {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 5px;
    }

    label.selected-color,
    label:hover {
        border: 2px solid $primary-color;
        margin: -2px;
        position: relative;

        &::before {
            display: block;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid white;
        }
    }

    button.not-allowed {
        cursor: not-allowed;
    }

    /* No color style created for the no color user option in QS for animals, cages, and tanks */
    button,
    label {

        aspect-ratio: 1 / 1;
        cursor: pointer;
        font-size: 0;

        &.color-none {
            background: $contrast-color;
            background: -moz-linear-gradient(150deg, $contrast-color 43%, $error-color  45%, $error-color  55%,$contrast-color 57%);
            background: -webkit-linear-gradient(-45deg, $contrast-color 43%,$error-color  45%,$error-color  55%,$contrast-color 57%);
            background: linear-gradient(135deg, $contrast-color 43%,$error-color  45%,$error-color  55%,$contrast-color 57%);
        }

        input[type=radio] {
            visibility: collapse;
        }
    }

}
