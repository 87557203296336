@import "scss/pyrat_basic";
.set_license_popup {

    .licence_added {
        border-bottom: 1px solid $second_color_300;
        transition: .1s;

        &.uncommon_placeholder {
            background: $shade-color-74;
        }
    }
}
