@import "pyrat_basic";

#tank_animal_import {

    div.details {

        table.parents {
            border: 1px solid $shade-color-74;
            padding: 2px;
            background-color: $contrast-color;
        }

        div.tanks {

            div.add {
                padding-bottom: 20px;

                span {
                    margin-right: 5px;
                }
            }

            .tank {
                font-weight: bold;

                .icon_button {
                    font-size: 17px;
                    line-height: 0;
                }
            }

            div.warning {
                padding-bottom: 20px;
            }

            div.summary {

                div.males,
                div.females,
                div.unknown {
                    border-top: 4px double $shade-color-50;
                    font-weight: bold;
                    padding-top: 10px;
                    text-align: center;
                }
            }
        }
    }
}
