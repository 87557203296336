@import "pyrat_basic";

@mixin fixed-center {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
}

#colony_pedigree {

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    #message {
        text-align: center;
        margin: 30px;
        font-weight: bold;
        font-size: $extra-large-font-size;
        color: $shade-color-74;
    }

    #controls {

        background-color: $shade-color-97;
        color: $primary-color;
        padding: 5px;
        margin: 10px 10px 5px 10px;
        border: 1px solid darken($shade-color-90, 6);
        input, select, div {
            vertical-align: middle;
        }

        .box {
            display: inline-block;

            label {
                margin-left: 10px;
                margin-right: 5px;
                &:first-child {
                    margin-left: 0;
                }
            }

            .selectem-wrapper {
                display: inline-block;
                min-width: 202px;
                vertical-align: top;
            }
        }

        .separator {
            display: inline-block;
            width: 0.1px;
            height: 25px;
            margin: 5px;
            padding: 0;
            border-right: 1px solid $basic_color_600;
        }

        @media print {
            display: none;
        }
    }

    .value_info {
        @include fixed-center;
        border: 1px solid $shade-color-90;
        border-radius: 10px;
        display: none;
        padding: 30px;
        background-color: $contrast-color;
        font-size: $extra-large-font-size * 2;
        opacity: 0.8;
    }

    #graph {

        flex-grow: 1;
        margin-bottom: 20px;
        overflow: auto;

        @media print {
            // in safari the div has 0px height on print, so display the content anyway
            overflow: visible;
        }

        svg {
            margin-bottom: 30px;

            @media print {
                // print node background colors by default
                -webkit-print-color-adjust: exact;
            }

            .edgePath path {
                stroke: $shade-color-30;
                stroke-width: 1.5px;
            }

            g.node {
                rect {
                    fill: $shade-color-93;
                    stroke: $shade-color-30;
                    stroke-width: 1.8;
                }

                &.root_node {
                    rect {
                        stroke-width: 2.5;
                        stroke: $basic_color_600;
                    }
                }

                &.list_node {
                    rect {
                        fill: $shade-color-97;
                        stroke: $shade-color-90;
                        stroke-width: 1.2;
                    }
                }

                div.children_node, div.siblings_node {
                    white-space: normal;

                    h1 {
                        font-size: $larger-font-size;
                        margin-bottom: 5px;
                        color: $shade-color-30;
                        font-weight: bold;
                    }
                }
            }

            &.animal_pedigree {

                g.node {

                    &.root_node {

                        h1.eartag {
                            background-color: $basic_color_600;
                            border-radius: 0 3px 3px 0;
                            color: $contrast-color;
                            margin-left: 30px;
                            font-weight: bold;
                            padding: 3px 0 3px 3px;
                        }
                    }

                    div.children_node, div.siblings_node {

                        div.birth {
                            margin-bottom: 5px;

                            &:last-child {
                                margin-bottom: inherit;
                            }

                            span.animal {
                                white-space: nowrap;
                                display: block;

                                @media not print {
                                    border: 1px solid $shade-color-74;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    line-height: $base-font-size * 2;
                                    padding: 1px 3px;

                                    &.animal_dead {
                                        text-decoration: line-through;
                                    }
                                }
                            }

                            span.comma {

                                @media not print {
                                    display: none;
                                }
                            }
                        }
                    }

                    div.animal_node {

                        h1.eartag {
                            font-size: $large-font-size;
                            white-space: nowrap;
                            cursor: default;
                            font-weight: bold;
                        }

                        span.gender_symbol {
                            float: left;
                            font-size: $extra-large-font-size;
                            margin-right: 5px;
                            width: 22px;
                            text-align: center;
                        }

                        div.strain {
                            background-color: $shade-color-90;
                            padding: 3px;
                            margin-bottom: 5px;
                            text-align: center;
                        }

                        div.mutation {
                            padding-left: 5px;

                            label {
                                display: inline-block;
                                font-style: italic;
                                color: $shade-color-30;
                            }

                            span {
                                padding-left: 5px;
                                font-weight: bold;
                                color: $primary-color;
                            }
                        }

                        div.day_of_birth,
                        div.day_of_death,
                        div.cagenumber {
                            margin-top: 2px;

                            span:nth-child(1) {
                                font-size: 16px;
                            }

                            span:nth-child(2) {
                              font-style: italic;
                            }
                        }

                        .actions {

                            @media print {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.tank_pedigree {

                g.node {

                    &.root_node {

                        h1.tank_id {
                            background-color: $basic_color_600;
                            border-radius: 3px;
                            padding: 0 3px;
                            color: $contrast-color;
                            font-weight: bold;
                        }
                    }

                    div.children_node, div.siblings_node {

                        &:last-child {
                            margin-bottom: inherit;
                        }

                        span.tank {
                            white-space: nowrap;

                            @media not print {
                                border: 1px solid $shade-color-74;
                                border-radius: 4px;
                                cursor: pointer;
                                line-height: $base-font-size * 2;
                                padding: 1px 3px;

                                &.tank_not_open {
                                    text-decoration: line-through;
                                }
                            }
                        }

                    }

                    div.tank_node {

                        h1.tank_id {
                            line-height: 24px;
                            font-size: $extra-large-font-size;
                            white-space: nowrap;
                            cursor: default;
                            font-weight: bold;

                            .tank_label {
                                font-style: italic;
                                float: right;
                                font-size: $medium-font-size;
                            }
                        }

                        table.animal_counts {
                            th {
                                cursor: pointer;
                                font-size: $medium-font-size;
                                line-height: $medium-font-size + 2px;
                            }

                            td {
                                font-size: $large-font-size;
                                padding: 2px;
                                min-width: 20px;
                            }
                        }

                        div.strain {
                            background-color: $shade-color-90;
                            padding: 3px;
                            margin-bottom: 5px;
                            text-align: center;
                        }

                        div.generation {
                            margin-top: 2px;

                            span:nth-child(1) {
                                font-size: 16px;
                            }

                            span:nth-child(2) {
                              font-style: italic;
                            }
                        }

                        .actions {
                            @media print {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.strain_pedigree {

                g.node {

                    &.root_node {

                        h1.strain_name {
                            color: $basic_color_600 !important;
                            font-weight: bold;
                        }
                    }

                    div.children_node {
                        div.children {
                            margin-bottom: 5px;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &:last-child {
                                margin-bottom: inherit;
                            }

                            span.child {
                                white-space: nowrap;

                                @media not print {
                                    border-radius: 4px;
                                    border: 1px solid $shade-color-74;
                                    cursor: pointer;
                                    background-color: $shade-color-90;
                                    line-height: $base-font-size * 2;
                                    padding: 1px 3px;
                                }
                            }

                            span.comma {
                                @media not print {
                                    display: none;
                                }
                            }
                        }
                    }

                    div.strain_node {

                        h1.strain_name {
                            color: $shade-color-30;
                            line-height: 25px;
                            font-size: $extra-large-font-size;
                            white-space: nowrap;
                            cursor: default;
                            margin-bottom: 5px;
                            font-weight: bold;
                        }

                        div.owner_name {
                            background-color: $shade-color-90;
                            padding: 3px;
                            margin-bottom: 5px;
                            text-align: center;
                        }

                        h2.mutations {
                            font-size: $base-font-size;
                            margin: 5px 0 0;
                        }

                        ul.mutations {
                            margin: 0;
                            padding-left: 20px;

                            li:last-child {
                                margin-bottom: 5px;
                            }
                        }

                        div.animal_count, div.pup_count, div.cage_count {

                            label {
                                display: inline-block;
                            }

                            span {
                                padding-left: 5px;
                                font-weight: bold;
                                color: $primary-color;
                            }
                        }

                        .actions {
                            @media print {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        @media print {
            background-color: white !important;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
}
