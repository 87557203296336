@import "pyrat_basic";

$selection-border-width: 1px;
$expansion-height: 200px;
$expansion-bottom-offset: ( $expansion-height * ( - 1 ) ) - $selection-border-width;

ko-selectem {
    display: flex;
    flex-direction: column;
    color: $primary-color;
    font-size: $base-font-size;
    margin: 1px 0;
    position: relative;

    .dummy-select {
        display: none;
    }

    // invalid-data attributes for a selectem element empty.
    &.invalid_data .selection {
        @include invalid-data;
    }

    .selection {
        flex: 0 0 auto;
        border-radius: 2px;
        border: $selection-border-width solid $shade-color-74;
        box-sizing: border-box;
        display: flex;
        min-height: 24px;
        background-color: $contrast-color;

        .caption {
            flex: 1 1 auto;
            padding-left: 3px;
            align-self: center;
            color: $shade-color-30;
            line-height: 14px !important; // overwrite line-height: 24px for span elements in list_filter.scss
        }

        .selected-options {
            flex: 1 1 auto;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 1px;
            max-height: 100px;
            overflow: auto;

            .option {
                border-radius: 2px;
                list-style: none;
                background-color: $shade-color-90;
                margin: 1px;
                display: flex;

                &.not-valid {
                    background-color: #fcc;
                }

                .label {
                    padding: 2px;
                    line-height: 14px !important; // overwrite line-height: 24px for span elements in list_filter.scss
                    word-break: break-all;  // fallback
                    word-break: break-word; // not standardized yet
                }

                .remove {
                    padding: 0 2px;
                    align-self: center;
                    line-height: 14px !important; // overwrite line-height: 24px for span elements in list_filter.scss
                }
            }
        }

        .expander {
            flex: 0 0 20px;
            font-family: $icons-font-family;
            font-size: 20px;
            color: $shade-color-74;
            text-align: center;
            align-self: center;

            &:after {
                content: "▽";
            }
        }

        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .expander {
                &:after {
                    content: "△";
                }
            }
        }

        &.disabled {
            background-color: $shade-color-97;
            border-color: $shade-color-93;

            .remove {
                display: none;
            }

            .expander:after {
                content: none;
            }
        }

        &:hover {
            background-color: $shade-color-97;
            border-color: $shade-color-74;
        }
    }

    &:focus, &:focus-within {
        .selection {
            border-color: $shade-color-30;
            outline-offset: 2px;
        }
    }

    .expansion {
        flex: 1 1 auto;
        border: 1px solid $shade-color-74;
        box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.3);
        height: $expansion-height;
        display: flex;
        flex-direction: column;
        background-color: $contrast-color;
        border-top-width: 0;
        border-radius: 0 0 2px 2px;
        overflow: hidden;

        &.overlay {
            position: absolute;
            z-index: 1;
            bottom: $expansion-bottom-offset;
            left: 0;
            right: 0;
        }

        .groups-wrapper {
            flex: 1 1 auto;
            overflow: auto;

            .groups-list {
                margin: 0;
                padding: 0;

                .group {
                    list-style: none;
                    cursor: default;
                    padding-top: 10px;
                    margin: 2px;
                    display: flex;

                    .checkbox {
                        flex: 0 0 auto;
                        align-self: center;
                        margin-right: 2px;
                    }

                    .label {
                        flex: 1 1 auto;
                        font-size: $large-font-size;
                        font-weight: bold;
                    }

                    .meta {
                        flex: 1 1 auto;
                        color: $second_color_700;
                        text-align: right;
                        font-style: italic;
                        font-weight: bold;
                    }

                    .expander {
                        flex: 0 0 20px;
                        font-family: $icons-font-family;
                        font-size: 20px;
                        color: $shade-color-74;
                        text-align: center;

                        &:after {
                            content: "▽";
                        }
                    }

                    &.expanded {
                        .expander {
                            &:after {
                                content: "△";
                            }
                        }

                    }
                }
            }

            .options {
                margin: 0;
                padding: 0;

                .option {
                    list-style: none;

                    .label-wrap {
                        display: flex;
                        margin: 2px 0;
                        padding: 2px !important; // overwrite padding-bottom: 6px and padding-right: 5px for label elements in list_filter.scss

                        .checkbox {
                            flex: 0 0 auto;
                            align-self: center;
                            margin-right: 2px;
                        }

                        .label {
                            flex: 1 1 auto;
                            align-self: center;
                            line-height: 14px; // overwrite line-height: 24px for span elements in list_filter.scss
                            padding: 0; // overwrite padding-bottom: 6px for label elements in list_filter.scss
                            word-break: break-all;  // fallback
                            word-break: break-word; // not standardized yet
                        }

                        .meta {
                            flex: 1 1 auto;
                            text-align: right;
                            align-self: center;
                            color: $second_color_700;
                            font-style: italic;
                            line-height: 14px; // overwrite line-height: 24px for span elements in list_filter.scss
                            padding: 0; // overwrite padding-bottom: 6px for label elements in list_filter.scss
                            word-break: break-all;  // fallback
                            word-break: break-word; // not standardized yet
                        }
                    }

                    &.selected,
                    &.highlight,
                    &:hover {
                        background-color: $second_color_100;
                    }
                }
            }
        }

        .filter {
            flex: 0 0 auto;
            border-top: 1px solid $shade-color-74;
            display: flex;
            background-color: $second_color_100;

            .input {
                flex: 1 1 auto;
                margin: 5px;
                width: 5px !important; // overwrite width: 100% for input elements in table.scss
            }

            .add {
                flex: 0 0 auto;
                margin: 5px 5px 5px 0;
            }
        }
    }
}
