.flatpickr-calendar {

  select.flatpickr-monthDropdown-months {

    // remove pyrat custom select dropdown arrow
    background: unset;

    // fix for too dark background in firefox with "dark" theme
    option.flatpickr-monthDropdown-month {
      background: white !important;
    }

  }

  // allow to define a button to sick on the right (usually the "close" button)
  .shortcut-buttons-flatpickr-buttons {
    width: 100%;

    .flatpickr-button-right {
      margin-left: auto;
    }
  }

}
