@import "pyrat_basic";

#session_poll_wrap {
    border: 0;
    display: none;
}

#session_poll_container {
    background: $shade-color-90;
    text-align: center;
    width: 500px;
    font-size: $medium-font-size;

    p {
        margin-top: 0;
        padding-top: 5px;
    }

    .message {
        display: none;
        width: 75%;
        margin: 10px auto;
    }

    input[type=text],
    input[type=password] {
        width: 150px;
    }
}

#session_poll_wrap table {
    margin: 0 auto;
    td {
        padding-bottom: 5px;
        text-align: left;
        &.col_2 {
            padding-left: 20px;
        }
        &.not_user {
            .subtle-link {
                margin-left: 10px;
                &:hover {
                    color: $second_color_900;
                }
            }
        }
    }
}

#session_submit {
    margin: 10px;
}
