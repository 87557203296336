@import "pyrat_basic";


.cage_hardware_widget {

    background-color: $contrast-color;
    padding-bottom: 20px;

    .reload_widget {
        opacity: .5;
        height: 40px;
    }

    div.actions {
        margin-bottom: 10px;
    }

    div.tecniplast {


        div.position {

            padding: 10px;
            background-color: $second_color_100;
            margin-bottom: 10px;

            label {
                font-weight: bold;
                color: $second_color_900;
                vertical-align: middle;
            }

            span.breadcrumbs {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 550px;
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }


        }

    }

    div.galilei {

        .meta {

            padding: 10px;
            background-color: $second_color_100;

            > div label {
                font-weight: bold;
                color: $second_color_900;
                vertical-align: middle;
                display: inline-block;
                min-width: 100px;

                + span {
                    margin-right: 10px;
                }
            }

        }

        .images {

            padding: 10px 5px;

            img {
                border: 1px solid $second_color_100;
                max-width: 32%;
            }
        }
    }

}

.connect_tecniplast_dvc_cage_popup {

    padding: 10px;

    div.locations {

        background-color: $contrast-color;
        margin-top: 10px;
        padding-bottom: 10px;
        overflow: auto;
        height: auto;
        max-height: 350px;

        div.no_possible_location {
            padding-top: 15px;
            text-align: center;
            color: $shade-color-30;
        }

        div.possible_location {

            padding: 5px;

            div.location {
                padding: 5px;
                margin-bottom: 5px;
                background-color: $shade-color-93;
            }

            &:hover div.location {
                background-color: $shade-color-90;
            }

            table {
                td {
                    padding: 6px 2px;
                    text-align: center;

                    a {
                        padding: 4px;
                    }
                }
            }
        }


    }

}
